import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BLPDealDetailComponent } from './blp-deal-detail/blp-deal-detail.component';
import { ConfirmEmailPageComponent } from './confirm-email/confirm-email.component';
import { DealDetailComponent } from './deal-detail/deal-detail.component';
import { DealsComponent } from './deals/deals.component';
import { EquityDealsComponent } from './equity-deals/equity-deals.component';
import { EquityDealComponent } from './equity-deal/equity-deal.component';
import { KYCPageComponent } from './KYC/kyc.component';
import { LoginPageComponent } from './login/login.component';
import { MainPageComponent } from './main/main.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { SignUpPageComponent } from './sign-up/sign-up.component';
import { VestingComponent } from './vesting/vesting.component';
import { StakingComponent } from './staking/staking.component';
import { FarmingComponent } from './farming/farming.component';
import { PoolDetailComponent } from './pool-detail/pool-detail.component';
import { CreatePoolComponent } from './create-pool/create-pool.component';
import { EditPoolComponent } from './edit-pool/edit-pool.component';
import { ClaimingComponent } from './claiming/claiming.component';
import { ClaimingDealDetailComponent } from './claiming-deal-detail/claiming-deal-detail.component';
import { CreateClaimingDealComponent } from './create-claiming-deal/create-claiming-deal.component';
import { EditClaimingDealComponent } from './edit-claiming-deal/edit-claiming-deal.component';
import { AddressBookComponent } from './address-book/address-book.component';
import { AuthGuard } from 'src/internal/authorize.guard';
import { NetworkGuard } from 'src/internal/network.guard';
import { SwingWidgetComponent } from './swing-widget-component/swing-widget.component';
import { DetailDealResolver } from './deal-detail/detail-deal.resolver';
import {UserPollsComponent} from "./user-polls/user-polls.component";
import { ReferralPageComponent } from './referral-page/referral.component';
import {KycGuard} from "../internal/kyc.guard";
import { UltradeWidgetComponent } from './ultrade-widget-component/ultrade-widget-component';
import { OtcDealsComponent } from './otc-deals/otc-deals.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: MainPageComponent,
        canActivate: [NetworkGuard],
      },
      {
        path: 'deals/:dealTypeFilter',
        component: DealsComponent,
        canActivate: [NetworkGuard],
      },
      {
        path: 'equity-deals',
        component: EquityDealsComponent,
        canActivate: [AuthGuard, NetworkGuard],
      },
      {
        path: 'equity-deal/:guid',
        component: EquityDealComponent,
        canActivate: [AuthGuard, NetworkGuard],
      },
      {
        path: 'portfolio',
        component: PortfolioComponent,
        canActivate: [AuthGuard, NetworkGuard]
      },
      {
        path: 'deal-detail',
        component: DealDetailComponent,
        canActivate: [NetworkGuard],
        resolve: {
          data: DetailDealResolver
          // is document-deal
          // is
        }
      },
      {
        path: 'blp-deal',
        component: BLPDealDetailComponent,
        canActivate: [NetworkGuard],
      },
      {
        path: 'vesting/:address',
        component: VestingComponent,
        canActivate: [NetworkGuard],
      },
      {
        path: 'signup',
        component: SignUpPageComponent,
      },
      {
        path: 'login',
        component: LoginPageComponent,
      },
      {
        path: 'confirm-email',
        component: ConfirmEmailPageComponent,
      },
      {
        path: 'kyc',
        component: KYCPageComponent,
        canActivate: [KycGuard],
      },
      {
        path: 'staking',
        component: StakingComponent,
        canActivate: [NetworkGuard],
      },
      {
        path: 'farming',
        component: FarmingComponent,
        canActivate: [NetworkGuard],
      },
      {
        path: 'pool-detail',
        component: PoolDetailComponent,
        canActivate: [NetworkGuard],
      },
      {
        path: 'create-pool',
        component: CreatePoolComponent,
      },
      {
        path: 'edit-pool',
        component: EditPoolComponent,
        canActivate: [NetworkGuard],
      },
      {
        path: 'claiming',
        component: ClaimingComponent,
      },
      {
        path: 'claiming-deal-detail',
        component: ClaimingDealDetailComponent,
      },
      {
        path: 'edit-claiming-deal',
        component: EditClaimingDealComponent,
      },
      {
        path: 'create-claiming-deal',
        component: CreateClaimingDealComponent,
      },
      {
        path: 'address-book',
        component: AddressBookComponent,
        canActivate: [NetworkGuard],
      },
      {
        path: 'swap-bridge',
        component: SwingWidgetComponent,
      },
      {
        path: 'voting',
        component: UserPollsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'referral',
        component: ReferralPageComponent,
        canActivate: [AuthGuard, NetworkGuard]
      },
      {
        path: 'ultrade-bridge',
        component: UltradeWidgetComponent,
      },
      {
        path: 'otc-deals',
        component: OtcDealsComponent,
      },
      { path: '**', redirectTo: '' },
      //{
      //  path: 'transfer',
      //  component: AppTransferComponent
      //}
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
