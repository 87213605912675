<info-join-deals *ngIf="dealTypeFilter == 4"></info-join-deals>

<section class="deals-section">
  <div class="__deals-list">
    <mat-tab-group *ngIf="dealTypeFilter != 4" mat-align-tabs="start" class="fullpage-tabs" disableRipple
                   selectedIndex="4">
      <mat-tab label="{{'upcoming' | translate}}">
        <div class="card not-found" *ngIf="upcomingDeals.length == 0">
          <div class="icon icomoon-box"></div>
          <div class="not-found__text">{{ 'noUpcomingDealsForNow' | translate }}</div>
        </div>

        <div class="card-list per-row-2">
          <div class="deal-card card cursor-pointer" *ngFor="let item of upcomingDeals; index as i">
            <deal-viewer [item]="item"></deal-viewer>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="{{'inProgress' | translate}}">
        <div class="card not-found" *ngIf="inProgressDeals.length == 0">
          <div class="icon icomoon-box"></div>
          <div class="not-found__text">{{ 'noDealsInProgressForNow' | translate }}</div>
        </div>
        <div class="card-list per-row-2">
          <div class="deal-card card cursor-pointer" *ngFor="let item of inProgressDeals; index as i">
            <deal-viewer [item]="item"></deal-viewer>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="{{'ended' | translate}}">
        <div class="card not-found" *ngIf="endedDeals.length == 0">
          <div class="icon icomoon-box"></div>
          <div class="not-found__text">{{ 'noFinishedDealsYet' | translate }}</div>
        </div>
        <div class="card-list per-row-2">
          <div class="deal-card card cursor-pointer" *ngFor="let item of endedDeals; index as i">
            <deal-viewer [item]="item"></deal-viewer>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="{{'all' | translate}}">
        <mat-spinner *ngIf="waitingDeals" class="__loading" diameter="40"></mat-spinner>

        <div class="card not-found" *ngIf="!waitingDeals && allDealsFiltered.length == 0">
          <div class="icon icomoon-box"></div>
          <div class="not-found__text">{{ 'noDealsYet' | translate }}</div>
        </div>
        <div class="card-list per-row-2">
          <div class="deal-card card cursor-pointer" *ngFor="let item of allDealsFiltered; index as i">
            <deal-viewer [item]="item"></deal-viewer>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>

    <mat-tab-group *ngIf="dealTypeFilter == 4" mat-align-tabs="start" class="fullpage-tabs" disableRipple
                   selectedIndex="0" (selectedTabChange)="onTabChange($event)">
      <mat-tab label="{{ 'tokenMarket' | translate }}">
        <div class="token-market-table">
          <div class="token-market-table-header">
            <p class="token-market-table-header-title">{{ 'tokenMarket' | translate }}</p>
            <div class="form-group-inline wrap-in-mob search-bar">
              <mat-form-field appearance="fill" class="icon-form-field">
                <i class="icon icomoon-search text-muted"></i>
                <mat-label>{{ 'search' | translate }}</mat-label>
                <input matInput  name="otcDealsSearchString" [(ngModel)]="otcDealsSearchString"
                       (ngModelChange)="onSearchChange($event)">
              </mat-form-field>
            </div>
          </div>

          <div class="token-market-table-title">
            <div class="row">
              <div class="col-2">{{ 'projectName' | translate }}</div>
              <div class="col-2">{{ 'token' | translate }}</div>
              <div class="col-2">{{ 'blockchain' | translate }}</div>
              <div class="col-2">{{ 'discount' | translate }}</div>
              <div class="col-2">{{ 'vesting' | translate }}</div>
              <div class="col-1">{{ 'totalRaise' | translate }}</div>
              <div class="col-1">{{ 'myAllocation' | translate }}</div>
            </div>
          </div>

          <div class="token-market-table-row" (click)="callDepositComponent(item)" *ngFor="let item of otcDealsSlice; index as i">
            <div class="row">
              <div class="col-2 row-item">{{item?.name}}</div>
              <div class="col-2 row-item"><img class="deal-logo" [src]="item?.logoURL ? item?.logoURL : '../../assets/images/no-image.svg'" />{{ item.rewardTokenSymbol }}</div>
              <div class="col-2 row-item"><img [src]="item.targetNetwork | imgNetworkPipe" class="network-name__img" alt="" /> <span class="network-name__title small-text">{{ item.targetNetwork | networkName}}</span></div>
              <div class="col-2 row-item">{{calculateDiscount(item)}}</div>
              <div class="col-2 row-item">{{item?.emissionDescription}}</div>
              <div class="col-1 row-item"><img [src]="item.paymentTokenSymbol | imgTokenPipe" width="24" /> {{getTotalRaiseETH(item)| number: '1.0-2'}}</div>
              <div class="col-1 row-item">{{ firstTokenUserTotalDebtAmount(item) | async }}</div>
            </div>
          </div>

          <mat-paginator
            [length]="otcDeals?.length"
            [pageSize]="20"
            (page)="onPageChange($event)"
            class="deals-paginator">
          </mat-paginator>
        </div>

      </mat-tab>

      <mat-tab label="{{ 'myTokens' | translate }}">
        <div class="token-market-table">
          <div class="token-market-table-header">
            <p class="token-market-table-header-title">{{ 'tokenMarket' | translate }}</p>
            <!--<div class="form-group-inline wrap-in-mob search-bar">
              <mat-form-field appearance="fill" class="icon-form-field">
                <i class="icon icomoon-search text-muted"></i>
                <mat-label>Search</mat-label>
                <input matInput  name="searchString" [(ngModel)]="myTokensSearchString"
                       (ngModelChange)="onMyTokensSearchChange($event)">
              </mat-form-field>
            </div>-->
          </div>

          <div class="token-market-table-title">
            <div class="row">
              <div class="col-2">{{ 'projectName' | translate }}</div>
              <div class="col-2">{{ 'token' | translate }}</div>
              <div class="col-2">{{ 'blockchain' | translate }}</div>
              <div class="col-2">{{ 'discount' | translate }}</div>
              <div class="col-2">{{ 'vesting' | translate }}</div>
              <div class="col-1">{{ 'totalRaise' | translate }}</div>
              <div class="col-1">{{ 'myAllocation' | translate }}</div>
            </div>
          </div>

          <div class="token-market-table-row" (click)="callDepositComponent(item)" *ngFor="let item of myTokensSlice; index as i">
            <div class="row">
              <div class="col-2 row-item">{{item?.name}}</div>
              <div class="col-2 row-item"><img class="deal-logo" [src]="item?.logoURL ? item?.logoURL : '../../assets/images/no-image.svg'" />{{ item.rewardTokenSymbol }}</div>
              <div class="col-2 row-item"><img [src]="item.targetNetwork | imgNetworkPipe" class="network-name__img" alt="" /> <span class="network-name__title small-text">{{ item.targetNetwork | networkName}}</span></div>
              <div class="col-2 row-item">{{calculateDiscount(item)}}</div>
              <div class="col-2 row-item">{{item?.emissionDescription}}</div>
              <div class="col-1 row-item"><img [src]="item.paymentTokenSymbol | imgTokenPipe" width="24" /> {{getTotalRaiseETH(item)| number: '1.0-2'}}</div>
              <div class="col-1 row-item">{{ firstTokenUserTotalDebtAmount(item) | async }}</div>
            </div>
          </div>

          <mat-paginator
            [length]="myTokensSlice?.length"
            [pageSize]="20"
            (page)="onPageChange2($event)"
            class="deals-paginator">
          </mat-paginator>
        </div>
      </mat-tab>

    </mat-tab-group>
  </div>
</section>

