<section [ngClass]="{'loading-page': waiting}">
  <section>
    <div class="modal-header">
      <h5 class="modal-title">{{'depositToPool' | translate}}</h5>
      <button class="btn-close" [mat-dialog-close]></button>
    </div>

    <div class="modal-body">
      <div class="deposit-modal-modal-body-header">
        <div class="token-info">
          <img [src]="deal?.logoURL ? deal?.logoURL : '../../assets/images/no-image.svg'" class="token-icon">
          <button class="token-btn --copy w-100" matTooltip="{{deal?.rewardToken}}" matTooltipClass="custom-tooltip" [cdkCopyToClipboard]="deal?.rewardToken">
            <span class="ref-link">{{ deal?.rewardTokenSymbol }}</span>
          </button>
          <p class="token-price" [innerHTML]="deal?.marketPrice | customNumberFormat"></p>
          <p class="token-price-2">{{tokenPrice| shiftDecimals:-paymentDecimal| number: '1.0-5'}}</p>
        </div>

        <div class="tier-info">
          <p class="tier-info-title">{{'yourTier' | translate}}</p>
          <img [src]="userTierIndex | imgTierPipe" [alt]="userTierIndex" class="tier-logo">
          <p class="tier-name">{{ getTranslatedTier(userTierIndex) }}</p>
        </div>
      </div>

      <div class="social-networks" *ngIf="hasSocialMedia()">
        <a *ngIf="deal?.twitterURL" [href]="deal?.twitterURL" target="_blank">
          <img src="../../assets/images/social/twitter.svg" alt="">
        </a>
        <a *ngIf="deal?.mediumURL" [href]="deal?.mediumURL" target="_blank">
          <img src="../../assets/images/social/medium.svg" alt="">
        </a>
        <a *ngIf="deal?.youTubeURL" [href]="deal?.youTubeURL" target="_blank">
          <img src="../../assets/images/social/youtube.svg" alt="">
        </a>
        <a *ngIf="deal?.facebookURL" [href]="deal?.facebookURL" target="_blank">
          <img src="../../assets/images/social/facebook.svg" alt="">
        </a>
        <a *ngIf="deal?.telegramURL" [href]="deal?.telegramURL" target="_blank">
          <img src="../../assets/images/social/telegram.svg" alt="">
        </a>
        <a *ngIf="deal?.linkedInURL" [href]="deal?.linkedInURL" target="_blank">
          <img src="../../assets/images/social/linkedin.svg" alt="">
        </a>
        <a *ngIf="deal?.instagramURL" [href]="deal?.instagramURL" target="_blank">
          <img src="../../assets/images/social/instagram.svg" alt="">
        </a>
        <a *ngIf="deal?.telegramChannelURL" [href]="deal?.telegramChannelURL" target="_blank">
          <img src="../../assets/images/social/telegram.svg" alt="">
        </a>
        <a *ngIf="deal?.coinGeckoURL" [href]="deal?.coinGeckoURL" target="_blank">
          <img src="../../assets/images/social/coinGecko.svg" alt="">
        </a>
        <a *ngIf="deal?.coinMarketCapURL" [href]="deal?.coinMarketCapURL" target="_blank">
          <img src="../../assets/images/social/coinMarketCap.svg" alt="">
        </a>
      </div>

      <div class="token-description">{{ deal?.description }}</div>

      <div class="row token-info-wrapper">
        <div class="col-lg-3" *ngIf="deal?.marketPrice">
          <p class="token-info-title">{{ 'discount' | translate }}</p>
          <p class="token-info-value">~{{ deal?.marketPrice && calculateDiscount(deal) }}%</p>
        </div>
        <div [class]="deal?.marketPrice ? 'col-lg-3': 'col-lg-4'">
          <p class="token-info-title">{{'vesting' | translate}}</p>
          <p class="token-info-value">{{ deal?.emissionDescription }}</p>
        </div>
        <div [class]="deal?.marketPrice ? 'col-lg-3': 'col-lg-4'">
          <p class="token-info-title">{{ 'remainingAllocation' | translate }}</p>
          <p
            class="token-info-value">{{ deal && calculateRemainingAllocation(deal)| number: '1.0-4' }} {{ paymentTokenSymbol }}</p>
        </div>
        <div [class]="deal?.marketPrice ? 'col-lg-3': 'col-lg-4'">
          <p class="token-info-title">{{'totalRaise' | translate}}</p>
          <p class="token-info-value">{{ deal && getTotalRaiseETH(deal)| number: '1.0-2' }} {{ paymentTokenSymbol }}</p>
        </div>
      </div>

      <div class="row token-info-wrapper" *ngIf="firstTokenUserTotalDebtAmount">
        <div class="col-lg-6 token-info-item">
          <p class="token-info-title text-center">{{'yourAllocation' | translate}}</p>
          <div class="token-info-value-wrapper">
            <img [src]="deal?.logoURL ? deal?.logoURL : '../../assets/images/no-image.svg'" class="token-icon">
            <p class="token-info-value">{{ usersDebtAmount && firstTokenUserTotalDebtAmount| number: '1.0-4' }} {{ deal?.customTokenName || rewardTokenSymbol }}</p>
          </div>
        </div>

        <div class="col-lg-6 token-info-item">
          <p class="token-info-title text-center">{{'yourDeposit' | translate}}</p>
          <div class="token-info-value-wrapper">
            <img [src]="paymentTokenSymbol | imgTokenPipe" width="24" class="token-icon" />
            <p class="token-info-value">{{usersTotalPaymentAmount| number: '1.0-4'}} {{paymentTokenSymbol}}</p>
          </div>
        </div>
      </div>

      <div class="d-md-flex align-items-center">
        <div>
          <div class="caption text-muted mb-1">{{deal?.dealType == 3 ? ('yourWalletAddress' | translate) : ('emissionAddress' | translate)}}</div>
          <div class="emission-address-block d-flex align-items-center mb-1">
            <span class="icon icon-sm icomoon-info text-error me-2" *ngIf="!emissionAddress"></span>
            <img [src]="deal?.targetNetwork | imgNetworkPipe" class="logo-20 me-2" />
            <div class="__emission-address small-text">{{emissionAddress ? emissionAddress : (deal?.dealType == 3 ? ('addWalletAddressClickingAddButton' | translate) : ('addEmissionAddressClickingAddButton' | translate))}}</div>
          </div>
          <div class="caption text-muted">{{deal?.dealType == 3 ? ('neededToConfirmYourPayment' | translate) : ('yourWalletAddressWhereProjectTokensSentYou' | translate)}}</div>
        </div>

        <div class="__emission-address__actions align-self-end">
          <button mat-raised-button color="primary" class="__emission-add btn-icon btn-icon-left"
                  (click)="openAddAddressDlg()" [disabled]="!account || !isAuthorized" *ngIf="!emissionAddress">
            <i class="icon icomoon-plus"></i>
            {{'add' | translate}}
          </button>
        </div>

        <div *ngIf="isUpcoming || isStarted" class="ms-auto align-self-end">
          <div class="subtitle-1" *ngIf="isUpcoming">{{'startsIn' | translate}}</div>
          <span class="subtitle-1" *ngIf="now > startTimestamp">{{'closesIn' | translate}}</span>

          <div class="countdown h6" >
            <span *ngIf="timerViewDays">{{timerViewDays}}{{'time.day' | translate}}</span>
            <span *ngIf="timerViewHours"> {{timerViewHours}}{{'time.hour' | translate}}</span>
            <span *ngIf="timerViewMin && !timerViewDays"> {{timerViewMin}}{{'time.minute' | translate}}</span>
            <span *ngIf="timerViewSec && !timerViewDays"> {{timerViewSec}}{{'time.second' | translate}}</span>
          </div>
        </div>
      </div>



      <section *ngIf="!deal?.preview && now < finishTimestamp && kycConfirmed">
        <div class="row pay-inputs">
          <div class="col-lg-6">
            <mat-form-field appearance="fill" hideRequiredMarker class="icon-form-field mb-4">
              <img [src]="paymentTokenSymbol | imgTokenPipe"/>
              <mat-label>{{ 'youPay' | translate }}</mat-label>
              <input matInput
                     type="number"
                     [(ngModel)]="usersTicketSize"
                     [style.width]="usersTicketSize.toString().length + 'ch'"
                     [min]="deal.dealType == 3 ? usersDebtAmount >= deal.minRewardTokenAmount ? 1 : deal.minRewardTokenAmount / tokensPerETH : 0"
                     [max]="ticketSizeToNumber - usersTotalPaymentAmount| number: '1.0-4'"
                     required
                     [ngClass]="amountInputClass"
                     (change)="recalcTicketSizeChange($event.target)"
                     [disabled]="deal.dealType == 2 || deal.dealType == 3 || !userTier || !account || !kycConfirmed || 0 <needToWaitTime || payInputDisabled">
              <button mat-button color="primary" class="max-btn"
                      (click)="setMaxUsersTicketSize(usersTotalPaymentAmount, usersDebtAmount)">MAX
              </button>
              <mat-hint>
                <div class="d-flex align-items-center justify-content-end">
                  {{ 'yourBalance' | translate }}: {{balance| number: '1.0-4'}} {{ paymentTokenSymbol }}
                </div>
              </mat-hint>
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field appearance="fill" hideRequiredMarker class="icon-form-field mb-4">
              <img [src]="deal?.logoURL ? deal?.logoURL : '../../assets/images/no-image.svg'"/>
              <mat-label>{{ 'youGet' | translate }}</mat-label>
              <input matInput
                     required
                     type="number"
                     [(ngModel)]="usersTicketSizeInRewardTokens"
                     [style.width]="usersTicketSizeInRewardTokens.toString().length + 'ch'"
                     [min]="deal.dealType == 2 ? 1 : deal.dealType == 3 ? usersDebtAmount >= deal.minRewardTokenAmount ? 1 : deal.minRewardTokenAmount : 0"
                     [max]="userTierMaxTokens - usersDebtAmount | number: '1.0-4'"
                     (change)="handleTicketSizeChange($event.target)"
                     (keypress)="handleKeyDown($event)"
                     [ngClass]="amountInputClass"
                     [disabled]="!userTier || !account || !kycConfirmed || 0 < needToWaitTime">
              <button mat-button color="primary" class="max-btn"
                      (click)="setMaxUsersTicketSize(usersTotalPaymentAmount, usersDebtAmount)">MAX
              </button>
              <mat-hint *ngIf="deal.marketPrice">
                <div class="d-flex align-items-center justify-content-end text-success">
                  {{ 'expectedEarnings' | translate }}: ~{{ expectedEarningsCalculation }}$
                </div>
              </mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="canPayMore && isAuthorized">
          <div class="row g-4">
            <!-- Approve -->
            <div class="col-lg"
                 matTooltip="{{!kycConfirmed ? ('youMustAuthorisedCompleteKycJoiningDeals' | translate) : ''}}"
                 matTooltipPosition="above">
              <button mat-raised-button color="primary" class="w-100 btn-icon btn-icon-left"
                      (click)="approvePaymentClick()" [ngClass]="{'btn-loading': waiting }"
                      [disabled]="waiting || !kycConfirmed || !userTier || isApprovedPaymentToken">
                <i class="icon icomoon-checkmark"></i>
                {{ 'approve' | translate }}
              </button>
            </div>

            <!-- equity deal pay button -->
            <div class="col-lg pay-wrapper" *ngIf="allowPay && isCollectWalletDeal && 0==needToWaitTime && isAuthorized"
                 matTooltip="{{!isApprovedPaymentToken ? ('mustApproveExactAmount' | translate) : !emissionAddress ? (deal.dealType == 3 ? ('addWalletAddressUnderDealCountdown' | translate) : ('addEmissionAddressUnderDealCountdown' | translate)) : ''}}"
                 matTooltipPosition="above">
              <button mat-raised-button color="primary" class="w-100" (click)="payClickWithWalletAddress()"
                      [ngClass]="{'btn-loading': waiting && isApprovedPaymentToken}"
                      [disabled]="waiting || !emissionAddress || !userTier || !account|| !isApprovedPaymentToken">
                {{'pay' | translate}}
              </button>
            </div>

            <!-- default deal pay button -->
            <div class="col-lg pay-wrapper"
                 *ngIf="allowPay && !isCollectWalletDeal && 0==needToWaitTime && isAuthorized"
                 matTooltip="{{!isApprovedPaymentToken ? ('mustApproveExactAmount' | translate) : ''}}"
                 matTooltipPosition="above">
              <button mat-raised-button color="primary" class="w-100" (click)="payClick()"
                      [ngClass]="{'btn-loading': waiting}"
                      [disabled]="waiting || !userTier || !account || !isApprovedPaymentToken">
                {{ 'pay' | translate }}
              </button>
            </div>

            <div class="col-lg" *ngIf="!allowPay">
              <button mat-raised-button color="secondary" type="button"
                      matTooltip="{{payTooltip}}" matTooltipPosition="above" class="w-100">
                <div class="countdown d-flex align-items-center justify-content-center">
                  <span *ngIf="timerViewDays">{{ timerViewDays }}{{ 'time.day' | translate }} </span>
                  <span *ngIf="timerViewHours"> {{ timerViewHours }}{{ 'time.hour' | translate }} </span>
                  <span
                    *ngIf="timerViewMin && !timerViewDays"> {{ timerViewMin }}{{ 'time.minute' | translate }} </span>
                  <span *ngIf="timerViewSec && !timerViewDays"> {{ timerViewSec }}{{ 'time.second' | translate }}</span>
                </div>
              </button>
            </div>

            <div class="col-lg" *ngIf="0<needToWaitTime" matTooltip="{{'theTimeWhenWillBeAbleMakePayment' | translate}}"
                 matTooltipPosition="above">
              <button mat-raised-button color="primary" class="w-100" disabled>
                {{ 'timeLockupEnds' | translate }}: {{ getTranslatedShowPeriod(needToWaitTime) }}
              </button>
            </div>
          </div>
        </div>

        <div class="row gx-2" *ngIf="!kycConfirmed && !isAuthorized">
          <div class="col-lg"
               matTooltip="{{'youMustAuthorisedCompleteKycJoiningDeals' | translate}}" matTooltipPosition="above">
            <button mat-raised-button color="primary" class="w-100 btn-icon btn-icon-left" disabled>
              <i class="icon icomoon-checkmark"></i>
              {{ 'approve' | translate }}
            </button>
          </div>
          <div class="col-lg" matTooltip="{{'youMustAuthorisedCompleteKycJoiningDeals' | translate}}"
               matTooltipPosition="above">
            <button mat-raised-button color="primary" class="w-100" disabled>{{ 'pay' | translate }}</button>
          </div>
        </div>

        <div *ngIf="kycConfirmed && !canPayMore">
          <div class="col-lg" matTooltip="{{'youCantPay' | translate}}" matTooltipPosition="above">
            <button mat-raised-button color="primary" class="w-100" disabled>{{ 'pay' | translate }}</button>
          </div>
        </div>

        <div class="mt-4" *ngIf="needToWaitTime">
          <div class="mb-2">
            <div class="caption text-muted mb-1">{{ 'lastParticipation' | translate }}:</div>
            <div class="small-text">
              {{ lastParticipationTime * 1000 | date:'dd MMM, yyyy H:mm a' : undefined : (translate.currentLang === 'vi' ? 'en' : translate.currentLang) }}
            </div>
          </div>
          <div>
            <div class="caption text-muted mb-1">{{ 'lockupDuration' | translate }}:</div>
            <div class="small-text">
              {{ getTranslatedShowPeriod(lockupDuration) }}
            </div>
          </div>
        </div>
      </section>

    </div>

  </section>
</section>
