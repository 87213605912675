<info-join-deals></info-join-deals>

<section class="deals-section">
  <div class="__deals-list">

    <mat-tab-group mat-align-tabs="start" class="fullpage-tabs" disableRipple
                   selectedIndex="0" (selectedTabChange)="onTabChange($event)">
      <mat-tab label="{{ 'tokenMarket' | translate }}">
        <div class="token-market-table">
          <div class="token-market-table-header">
            <p class="token-market-table-header-title">{{ 'tokenMarket' | translate }}</p>
            <div class="form-group-inline wrap-in-mob search-bar">
              <mat-form-field appearance="fill" class="icon-form-field">
                <i class="icon icomoon-search text-muted"></i>
                <mat-label>{{ 'search' | translate }}</mat-label>
                <input matInput  name="otcDealsSearchString" [(ngModel)]="otcDealsSearchString"
                       (ngModelChange)="onSearchChange($event)">
              </mat-form-field>
            </div>
          </div>

          <div class="token-market-table-title">
            <div class="row">
              <div class="col-2">{{ 'projectName' | translate }}</div>
              <div class="col-2">{{ 'token' | translate }}</div>
              <div class="col-2">{{ 'blockchain' | translate }}</div>
              <div class="col-2">{{ 'discount' | translate }}</div>
              <div class="col-2">{{ 'vesting' | translate }}</div>
              <div class="col-2">{{ 'totalRaise' | translate }}</div>
            </div>
          </div>

          <div class="token-market-table-row" (click)="callDepositComponent(item)" *ngFor="let item of otcDealsSlice; index as i">
            <div class="row">
              <div class="col-2 row-item">{{item?.name}}</div>
              <div class="col-2 row-item"><img class="deal-logo" [src]="item?.logoURL ? item?.logoURL : '../../assets/images/no-image.svg'" />{{ item.rewardTokenSymbol }}</div>
              <div class="col-2 row-item"><img [src]="item.targetNetwork | imgNetworkPipe" class="network-name__img" alt="" /> <span class="network-name__title small-text">{{ item.targetNetwork | networkName}}</span></div>
              <div class="col-2 row-item">{{calculateDiscount(item)}}</div>
              <div class="col-2 row-item">{{item?.emissionDescription}}</div>
              <div class="col-2 row-item"><img [src]="item.paymentTokenSymbol | imgTokenPipe" width="24" /> {{getTotalRaiseETH(item)| number: '1.0-2'}}</div>
            </div>

            <div class="token-market-item">
              <div class="token-market-item-context">
                <p class="token-market-item-title">{{ 'projectName' | translate }}</p>
                <p class="token-market-item-value">{{item?.name}}</p>
              </div>
              <div class="token-market-item-context">
                <p class="token-market-item-title">{{ 'token' | translate }}</p>
                <p class="token-market-item-value"><img class="deal-logo" [src]="item?.logoURL ? item?.logoURL : '../../assets/images/no-image.svg'" />{{ item.rewardTokenSymbol }}</p>
              </div>
              <div class="token-market-item-context">
                <p class="token-market-item-title">{{ 'blockchain' | translate }}</p>
                <p class="token-market-item-value"><img [src]="item.targetNetwork | imgNetworkPipe" class="deal-logo" alt="" /> <span class="network-name__title small-text">{{ item.targetNetwork | networkName}}</span></p>
              </div>
              <div class="token-market-item-context">
                <p class="token-market-item-title">{{ 'discount' | translate }}</p>
                <p class="token-market-item-value">{{calculateDiscount(item)}}</p>
              </div>
              <div class="token-market-item-context">
                <p class="token-market-item-title">{{ 'vesting' | translate }}</p>
                <p class="token-market-item-value">{{item?.emissionDescription}}</p>
              </div>
              <div class="token-market-item-context">
                <p class="token-market-item-title">{{ 'totalRaise' | translate }}</p>
                <p class="token-market-item-value"><img [src]="item.paymentTokenSymbol | imgTokenPipe" width="24" /> {{getTotalRaiseETH(item)| number: '1.0-2'}}</p>
              </div>
            </div>
          </div>

          <mat-paginator
            [length]="otcDeals?.length"
            [pageSize]="20"
            (page)="onPageChange($event)"
            class="deals-paginator">
          </mat-paginator>
        </div>

      </mat-tab>

      <mat-tab label="{{ 'myTokens' | translate }}">
        <div class="token-market-table">
          <div class="token-market-table-header">
            <p class="token-market-table-header-title">{{ 'tokenMarket' | translate }}</p>
            <!--<div class="form-group-inline wrap-in-mob search-bar">
              <mat-form-field appearance="fill" class="icon-form-field">
                <i class="icon icomoon-search text-muted"></i>
                <mat-label>Search</mat-label>
                <input matInput  name="searchString" [(ngModel)]="myTokensSearchString"
                       (ngModelChange)="onMyTokensSearchChange($event)">
              </mat-form-field>
            </div>-->
          </div>

          <div class="token-market-table-title">
            <div class="row">
              <div class="col-2">{{ 'projectName' | translate }}</div>
              <div class="col-2">{{ 'token' | translate }}</div>
              <div class="col-2">{{ 'blockchain' | translate }}</div>
              <div class="col-1">{{ 'discount' | translate }}</div>
              <div class="col-2">{{ 'vesting' | translate }}</div>
              <div class="col-1">{{ 'totalRaise' | translate }}</div>
              <div class="col-2">{{ 'myAllocation' | translate }}</div>
            </div>
          </div>

          <div class="token-market-table-row" (click)="callDepositComponent(item)" *ngFor="let item of myTokensSlice; index as i">
            <div class="row">
              <div class="col-2 row-item">{{item?.name}}</div>
              <div class="col-2 row-item"><img class="deal-logo" [src]="item?.logoURL ? item?.logoURL : '../../assets/images/no-image.svg'" />{{ item.rewardTokenSymbol }}</div>
              <div class="col-2 row-item"><img [src]="item.targetNetwork | imgNetworkPipe" class="network-name__img" alt="" /> <span class="network-name__title small-text">{{ item.targetNetwork | networkName}}</span></div>
              <div class="col-1 row-item">{{calculateDiscount(item)}}</div>
              <div class="col-2 row-item">{{item?.emissionDescription}}</div>
              <div class="col-1 row-item"><img [src]="item.paymentTokenSymbol | imgTokenPipe" width="24" /> {{getTotalRaiseETH(item)| number: '1.0-2'}}</div>
              <div class="col-2 row-item"><img class="deal-logo" [src]="item?.logoURL ? item?.logoURL : '../../assets/images/no-image.svg'" />{{ firstTokenUserTotalDebtAmount(item) | async }}</div>
            </div>

            <div class="token-market-item">
              <div class="token-market-item-context">
                <p class="token-market-item-title">{{ 'projectName' | translate }}</p>
                <p class="token-market-item-value">{{item?.name}}</p>
              </div>
              <div class="token-market-item-context">
                <p class="token-market-item-title">{{ 'token' | translate }}</p>
                <p class="token-market-item-value"><img class="deal-logo" [src]="item?.logoURL ? item?.logoURL : '../../assets/images/no-image.svg'" />{{ item.rewardTokenSymbol }}</p>
              </div>
              <div class="token-market-item-context">
                <p class="token-market-item-title">{{ 'blockchain' | translate }}</p>
                <p class="token-market-item-value"><img [src]="item.targetNetwork | imgNetworkPipe" class="deal-logo" alt="" /> <span class="network-name__title small-text">{{ item.targetNetwork | networkName}}</span></p>
              </div>
              <div class="token-market-item-context">
                <p class="token-market-item-title">{{ 'discount' | translate }}</p>
                <p class="token-market-item-value">{{calculateDiscount(item)}}</p>
              </div>
              <div class="token-market-item-context">
                <p class="token-market-item-title">{{ 'vesting' | translate }}</p>
                <p class="token-market-item-value">{{item?.emissionDescription}}</p>
              </div>
              <div class="token-market-item-context">
                <p class="token-market-item-title">{{ 'totalRaise' | translate }}</p>
                <p class="token-market-item-value"><img [src]="item.paymentTokenSymbol | imgTokenPipe" width="24" /> {{getTotalRaiseETH(item)| number: '1.0-2'}}</p>
              </div>
              <div class="token-market-item-context">
                <p class="token-market-item-title">{{ 'myAllocation' | translate }}</p>
                <p class="token-market-item-value"><img class="deal-logo" [src]="item?.logoURL ? item?.logoURL : '../../assets/images/no-image.svg'" />{{ firstTokenUserTotalDebtAmount(item) | async }}</p>
              </div>
            </div>
          </div>

          <mat-paginator
            [length]="myTokensSlice?.length"
            [pageSize]="20"
            (page)="onPageChange2($event)"
            class="deals-paginator">
          </mat-paginator>
        </div>
      </mat-tab>

    </mat-tab-group>
  </div>
</section>

