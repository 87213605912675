import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ComponentBase } from '../../shared/component-base';
import { Web3Service } from '../../shared/web3-service';
import { BigNumber } from "bignumber.js";
import { UserSessionProvider } from '../../shared/user-session-provider';
import { DealDTO, DealServiceProxy } from '../../service-proxies/service-proxies';
import {ActivatedRoute, Router} from '@angular/router';
import {NoopScrollStrategy} from "@angular/cdk/overlay";
import {DlgDepositModalComponent} from "../dlg-deposit-modal";
import {TranslateService} from "@ngx-translate/core";
import {map} from "rxjs/operators";
import {from, Observable} from "rxjs";
import {MatPaginator, PageEvent} from "@angular/material/paginator";

@Component({
  selector: 'app-otc-deals',
  templateUrl: './otc-deals.component.html',
  styleUrl: './otc-deals.component.scss'
})
export class OtcDealsComponent extends ComponentBase implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  constructor(
    private web3Service: Web3Service,
    private dealService: DealServiceProxy,
    private userSessionProvider: UserSessionProvider,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _dialog: MatDialog,
    public translate: TranslateService
  ) {
    super();
  }
  private userDebtCache = new Map<string, Observable<string>>();

  public tabType: string = "verified";
  public upcomingDeals: Array<DealDTO> = [];
  public inProgressDeals: Array<DealDTO> = [];
  public endedDeals: Array<DealDTO> = [];
  public allDealsFiltered: Array<DealDTO> = [];
  public allDeals: Array<DealDTO> = [];
  public otcDeals: Array<DealDTO> = [];
  public nowTimeStamp: number = Math.floor(Date.now() / 1000);
  public dealTypeFilter = 0;
  public page: number = 0;
  private registeredEquityDeals: DealDTO[] = [];
  private registeredOTCDeals: DealDTO[] = [];
  otcDealsSlice: any
  myTokensSlice: any
  otcDealsSearchString: string = '';
  myTokensSearchString: string = '';

  waiting: boolean = false;
  waitingDeals: boolean = true;

  ngAfterViewInit() {
    this.translateLabels();
    this.translate.onLangChange.subscribe(() => {
      this.translateLabels();
    });
  }

  async ngOnInit() {
    this.clearUserDebtCache();
    this.dealTypeFilter = 4;
    this.filterDeals();
    await this.web3Service.initWeb3();
    window.dispatchEvent(new Event('resize'));
    this.dealService.getForChain(this.web3Service.chainIdNumber)
      .subscribe(result => {
        this.allDeals = result;
        this.waitingDeals = false;
        this.filterDeals();
      },
        error => {
          console.error(error);
        });

  }

  translateLabels() {
    this.translate.get('paginator.itemsPerPage').subscribe((res) => {
      console.log('trans', res)
      this.paginator._intl.itemsPerPageLabel = res;
      this.paginator._intl.nextPageLabel = this.translate.instant('paginator.nextPage');
      this.paginator._intl.previousPageLabel = this.translate.instant('paginator.previousPage');
      this.paginator._intl.firstPageLabel = this.translate.instant('paginator.firstPage');
      this.paginator._intl.lastPageLabel = this.translate.instant('paginator.lastPage');
      this.paginator._intl.changes.next();
    });
  }

  async filterDeals() {
    this.userDebtCache.clear();

    if (this.dealTypeFilter == 4 && this.userSessionProvider.linkedWallet) {
      let dealsUserRegistered = await this.dealService.getDealWhereUserRegistrated(this.userSessionProvider.linkedWallet).toPromise();
      this.registeredOTCDeals = dealsUserRegistered.filter((deal) => deal.dealType == 4)
    }
    this.otcDeals = this.allDeals.filter((d) => d.dealType == 4)
    this.otcDealsSlice = this.otcDeals.slice(0, 20);
    this.allDealsFiltered = this.allDeals.filter(p => this.filterDealCondition(p));
    this.myTokensSlice = this.registeredOTCDeals.slice(0, 20)

    this.upcomingDeals = this.allDealsFiltered.filter(p => this.nowTimeStamp < p.startTime);
    this.inProgressDeals = this.allDealsFiltered.filter(p => this.nowTimeStamp >= p.startTime && this.nowTimeStamp <= p.finishTime);
    this.endedDeals = this.allDealsFiltered.filter(p => this.nowTimeStamp > p.finishTime);
  }

  filterDealCondition(p: DealDTO): boolean {
      return (p.dealType == this.dealTypeFilter) && (p.dealType != 3 || this.registeredEquityDeals.some(s => s.dealAddress === p.dealAddress));
  }

  public getTotalRaiseETH(deal: DealDTO): number {
    return new BigNumber(deal.maxDistributedTokenAmount).shiftedBy(-deal.rewardDecimal).multipliedBy(deal.tokenPrice).shiftedBy(-deal.paymentDecimal).toNumber();
  }

  public getRatioPer1ETH(deal: DealDTO): number {
    return new BigNumber(1).shiftedBy(deal.paymentDecimal).dividedBy(deal.tokenPrice).toNumber()
  }

  async getDiscountPercent(dealAddress: string): Promise<any> {
    let vestPercent
    if (this.web3Service.web3) {
    await this.web3Service.getVestingPercent(dealAddress).then((resp) => {
      vestPercent = parseInt(resp);
    });
    }
    return vestPercent
  }

  async getVestingDuration(dealAddress: string): Promise<any> {
    let vestAddress = await this.web3Service.getDealVestingAddress(dealAddress);
    let vestingDuration
    this.web3Service.getVVestingDuration(vestAddress).then((resp) => {
      vestingDuration = parseInt(resp);
    });
    return  this.getTranslatedShowPeriod(vestingDuration)
  }

  getTranslatedShowPeriod(value: number): string {
    const timerViewDays = Math.floor(value / (3600 * 24));
    const timerViewHours = Math.floor(value % (3600 * 24) / 3600);
    const timerViewMin = Math.floor(value % 3600 / 60);
    const timerViewSec = Math.floor(value % 60);
    let stringData = "";
    if (timerViewDays)
      stringData += `${timerViewDays} ${this.translate.instant('time.day')} `;
    if (timerViewHours)
      stringData += `${timerViewHours} ${this.translate.instant('time.hours')} `;
    if (timerViewMin)
      stringData += `${timerViewMin} ${this.translate.instant('time.min')} `;
    if (timerViewSec)
      stringData += `${timerViewSec} ${this.translate.instant('time.ss')} `;
    return stringData;
  }
  firstTokenUserTotalDebtAmount(deal: DealDTO): Observable<string> {
    if (!deal || !deal.dealAddress) {
      return new Observable((observer) => observer.next("N/A"));
    }

    // Check if the result is already cached
    if (!this.userDebtCache.has(deal.dealAddress)) {
      const debtCalculation$ = from(
        this.web3Service.getDealUserInfo(deal.dealAddress, this.userSessionProvider.linkedWallet)
      ).pipe(
        map((userInfo) => {
          const usersDebtAmount = this.toNumberFromWei(userInfo.debt, 18);
          return this.getFistCustomTokenInfo(deal, usersDebtAmount);
        }),
        // Cache the result to avoid recalculating
        map((result) => {
          this.userDebtCache.set(deal.dealAddress, new Observable((observer) => observer.next(result)));
          return result;
        })
      );

      this.userDebtCache.set(deal.dealAddress, debtCalculation$);
    }

    // Return the cached observable
    return this.userDebtCache.get(deal.dealAddress)!;
  }

  getFistCustomTokenInfo(deal: DealDTO, tokenField: number | string): string {
    if (deal.customPrice && deal.investmentPercentage) {
      return (
        (Number(tokenField) *
          Number(deal.investmentPercentage) /
          Number(100) /
          Number(deal.customPrice) *
          Number(deal.tokenPrice))
          .toFixed(2)
          .replace(/[.,]00$/, "")
      );
    }
    return tokenField.toString();
  }

  public callDepositComponent(deal: DealDTO): void{
    const dialogRef = this._dialog.open(DlgDepositModalComponent, {
      panelClass: ['dlg-light', 'dlg-large'],
      scrollStrategy: new NoopScrollStrategy(),
      restoreFocus: true
    });
    dialogRef.componentInstance.dealDetails = deal;
    dialogRef.afterClosed().subscribe((result: any) => {
      this.dealService.getForChain(this.web3Service.chainIdNumber)
        .subscribe(result => {
            this.allDeals = result;
            this.waitingDeals = false;
            this.filterDeals();
          },
          error => {
            console.error(error);
          });
    });
  }

  public calculateDiscount(deal: DealDTO): string {
    var value = parseFloat(String((deal?.marketPrice - new BigNumber(deal?.tokenPrice).shiftedBy(-deal.paymentDecimal).toNumber()) / deal?.marketPrice * 100)).toFixed(2);
    if(!Number.isFinite(Number(value))){
      return "";
    }
    return `${value}%`;
  }

  clearUserDebtCache(): void {
    this.userDebtCache.clear();
  }

  onPageChange(event?: PageEvent) {
    const startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if (endIndex > this.otcDeals.length) {
      endIndex = this.otcDeals.length
    }
    this.otcDealsSlice = this.otcDeals.slice(startIndex, endIndex);
  }

  onPageChange2(event?: PageEvent) {
    const startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if (endIndex > this.myTokensSlice.length) {
      endIndex = this.myTokensSlice.length
    }
    this.myTokensSlice = this.myTokensSlice.slice(startIndex, endIndex);
  }

  onTabChange(event: any): void {
    if (event.index === 1 && !this.userSessionProvider.authToken) {
      this.router.navigate(['/login']);
    }
  }

  filterOTCDeals() {
    this.otcDeals = this.allDeals.filter((d) => d.dealType === 4 &&
      (!this.otcDealsSearchString || d.name.toLowerCase().includes(this.otcDealsSearchString.toLowerCase())));
    this.otcDealsSlice = this.otcDeals.slice(0, 20);
  }

  onSearchChange(search: string) {
    this.otcDealsSearchString = search;
    this.filterOTCDeals();
  }
}
