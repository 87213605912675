import { Pipe, PipeTransform } from "@angular/core";
import {DecimalPipe} from "@angular/common";

@Pipe({
    name: "customNumberFormat"
})
export class CustomNumberFormatPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}
  transform(value: number): string {
    if (value === 0) {
      return '0';
    }

    const formattedValue = this.decimalPipe.transform(value, '1.0-20') || '';

    const [integerPart, fractionalPart] = formattedValue.split('.');

    if (fractionalPart) {
      const leadingZerosMatch = fractionalPart.match(/^0+/);
      const leadingZerosCount = leadingZerosMatch ? leadingZerosMatch[0].length : 0;

      if (leadingZerosCount >= 5) {
        const significantDigits = fractionalPart.slice(leadingZerosCount);
        return `${integerPart}.0<sub>${leadingZerosCount}</sub>${significantDigits}`;
      }
    }


    return this.decimalPipe.transform(value, '1.0-5') || '';
  }
}
