import {environment} from "src/environments/environment";

export interface MenuItem {
  readonly id: number;
  readonly label: string,
  readonly translationKey: string,
  readonly routePath: string[] | string,
  readonly supportedChains: number[],
  readonly icon?: string;
  readonly childItems?: MenuItem[],
  readonly isExternal?: boolean;
  hidden?: boolean;
}

export const menu: MenuItem[] = [
  {
    id: 0,
    icon: "icomoon-dashboard",
    label: "Dashboard",
    translationKey: 'dashboard',
    routePath: "/",
    supportedChains: [56, 97],
  },
  {
    id: 1,
    icon: "icomoon-address-book",
    label: "Address Book",
    translationKey: 'addressBook',
    routePath: "/address-book",
    supportedChains: [56, 97],
  },
  {
    id: 2,
    icon: "icomoon-users",
    label: "Referral",
    translationKey: 'referral',
    routePath: "/referral",
    supportedChains: [],
  },
  {
    id: 3,
    icon: "icomoon-kys",
    label: "KYC",
    translationKey: 'kyc',
    routePath: "/kyc",
    supportedChains: [],
  },
  {
    id: 4,
    icon: "icomoon-market",
    label: "Deals",
    translationKey: 'deals',
    routePath: ["/deal-detail", "/deals/0", "/deals/1", "/deals/2", "/equity-deals", "/equity-deal/:id", "/otc-deals"],
    supportedChains: [56, 97],
    childItems: [{
      id: 5,
      label: "VC Deals",
      translationKey: 'vcDeals',
      routePath: "/deals/0",
      supportedChains: [56, 97],
    },
      {
        id: 6,
        label: "IDO Deals",
        translationKey: 'idoDeals',
        routePath: "/deals/1",
        supportedChains: [56, 97],
      },
      {
        id: 7,
        label: "INO Deals",
        translationKey: 'inoDeals',
        routePath: "/deals/2",
        supportedChains: [56, 97],
      },
      {
        id: 8,
        label: "Equity Deals",
        translationKey: 'equityDeals',
        routePath: "/equity-deals",
        supportedChains: [56, 97],
      },
      {
        id: 9,
        label: "My Equity Deals",
        translationKey: 'myEquityDeals',
        routePath: "/deals/3",
        supportedChains: [56, 97],
        hidden: true
      },
      {
        id: 10,
        label: "OTC Deals",
        translationKey: 'otcDeals',
        routePath: "/otc-deals",
        supportedChains: [56, 97]
      }
    ]
  },
  {
    id: 11,
    label: "Pools",
    translationKey: 'pools',
    icon: "icomoon-pools",
    routePath: ["/staking", "/farming", "/pool-detail"],
    supportedChains: [56, 97],
    childItems: [{
      id: 12,
      label: "Staking Pools",
      translationKey: 'stakingPools',
      routePath: "/staking",
      supportedChains: [56, 97],
    },
      {
        id: 13,
        label: "Farming Pools",
        translationKey: 'farmingPools',
        routePath: "/farming",
        supportedChains: [56, 97],
      }]
  },
  {
    id: 14,
    label: "Claim Tokens",
    translationKey: 'claimTokens',
    icon: "icomoon-claim",
    routePath: ["/vesting", `/vesting/${environment.bsc.vestingSeedAddress}`, `/vesting/${environment.bsc.vestingStrategicAddress}`, `/vesting/${environment.bsc.vestingPrivateAddress}`, `/vesting/${environment.bsc.vestingBountyAddress}`],
    supportedChains: [56, 97],
    childItems: [{
      id: 15,
      label: "Claim Seed",
      translationKey: 'claimSeed',
      routePath: `/vesting/${environment.bsc.vestingSeedAddress}`,
      supportedChains: [56, 97],
    },
      {
        id: 16,
        label: "Claim Strategic",
        translationKey: 'claimStrategic',
        routePath: `/vesting/${environment.bsc.vestingStrategicAddress}`,
        supportedChains: [56, 97],
      },
      {
        id: 17,
        label: "Claim Private",
        translationKey: 'claimPrivate',
        routePath: `/vesting/${environment.bsc.vestingPrivateAddress}`,
        supportedChains: [56, 97],
      },
      {
        id: 18,
        label: "Claim Bounty",
        translationKey: 'claimBounty',
        routePath: `/vesting/${environment.bsc.vestingBountyAddress}`,
        supportedChains: [56, 97],
      }]
  },
  {
    id: 19,
    label: "Portfolio",
    translationKey: 'portfolio',
    icon: "icomoon-portfolio",
    routePath: "/portfolio",
    supportedChains: [56, 97],
  },
  {
    id: 20,
    label: "Swap & Bridge",
    translationKey: 'swapBridge',
    icon: "icomoon-market",
    routePath: '/swap-bridge',
    supportedChains: [],
  },
  {
    id: 21,
    icon: 'icomoon-info',
    label: 'Voting',
    translationKey: 'voting',
    routePath: '/voting',
    supportedChains: [56, 97],
  },
  {
    id: 22,
    label: "Help Center",
    translationKey: 'helpCenter',
    icon: "icomoon-help",
    isExternal: true,
    routePath: "https://help.bullperks.com/",
    supportedChains: [],
  }
];
